exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-article-1-js": () => import("./../../../src/pages/article1.js" /* webpackChunkName: "component---src-pages-article-1-js" */),
  "component---src-pages-article-2-js": () => import("./../../../src/pages/article2.js" /* webpackChunkName: "component---src-pages-article-2-js" */),
  "component---src-pages-article-3-js": () => import("./../../../src/pages/article3.js" /* webpackChunkName: "component---src-pages-article-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magazine-js": () => import("./../../../src/pages/magazine.js" /* webpackChunkName: "component---src-pages-magazine-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */)
}

